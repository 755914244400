"use strict";

const {
  MFA_TYPES
} = require('../api/constants');

// SUB-2623 security policies (concept)
// SUB-2629 security policies display

const policy_groups = [{
  id: "password",
  name: "Password Rules"
}, {
  id: "mfa",
  name: "MFA (Multi-Factor Authentication)"
}, {
  id: "failed-login",
  name: "Failed Login Attempts Handling"
}];
function password_expire_value_to_days(v) {
  const expIn = v.match(/(?<number>^\d+)\s*(?<unit>[dwm]{1})$/i);
  let days;
  if (expIn) {
    const {
      number,
      unit
    } = expIn.groups;
    days = number * {
      'd': 1,
      'w': 7,
      'm': 30
    }[unit.toLowerCase()];
  }
  return days;
}

// https://builds.atlassian.net/browse/SUB-2630
function render_PasswordExpire(value) {
  let retval = '';
  if (!value) return retval;
  const m = value.match(/(?<number>^\d+)\s*(?<unit>[dwm]{1})$/i);
  const {
    number,
    unit
  } = m.groups;
  retval += number;
  switch (unit) {
    case 'd':
      retval += ' day';
      break;
    case 'w':
      retval += ' week';
      break;
    case 'm':
      retval += ' month';
      break;
  }
  if (unit != 1) retval += 's';
  return retval;
}
function render_MFA(value) {
  if ((value || {}).method === MFA_TYPES.EOTP) return 'Verification code (OTP) via email';
  return '';
}
const policies = {
  no_password_reuse: {
    group: 'password',
    type: 'boolean',
    desc: 'Can not reuse previously used passwords',
    comment: 'Checked when setting up a new password.',
    account_manageable: true
  },
  no_password_reuse_company: {
    group: 'password',
    type: 'boolean',
    levels: 'company',
    desc: 'Different users within the company can not share the same password',
    comment: 'Checked when setting up a new password.',
    account_manageable: true
  },
  // https://builds.atlassian.net/browse/SUB-2630
  passwords_expire: {
    group: 'password',
    type: 'string:PasswordExpire',
    render_value: render_PasswordExpire,
    levels: 'company',
    desc: 'Passwords expire after a specified amount of time',
    values: [{
      v: '-',
      l: 'never'
    }, {
      v: '3m',
      l: '3 months'
    }, {
      v: '6m',
      l: '6 months'
    }, {
      v: '365d',
      l: '1 year'
    }, {
      v: '730d',
      l: '2 years'
    }],
    acceptable_value: function (system, option) {
      if (!system) return true;
      if (option === '-') return false;
      const system_days = password_expire_value_to_days(system),
        option_days = password_expire_value_to_days(option);
      return option_days >= system_days;
    },
    account_manageable: true
  },
  mfa: {
    group: 'mfa',
    type: 'object:MFA',
    render_value: render_MFA,
    levels: 'company',
    desc: 'Login must be verified via another method (MFA)'
    /* thinking, maybe...
    'values': [
      {v: 'optional',  l: 'Optional'},
      {v: 'recommend', l: 'Recommend'},
      {v: 'required',  l: 'Required'}
    ]
    */
  },

  notify_owner_after_failed_login_attempts: {
    group: 'failed-login',
    type: 'integer',
    levels: 'application,company',
    desc: 'Notify the user after some failed login attempts, # of attempts',
    acceptable_value: function (system, option) {
      if (!system) return true;
      return system <= option;
    },
    account_manageable: false
  },
  soft_lock_after_failed_login_attempts: {
    group: 'failed-login',
    type: 'integer',
    levels: 'company',
    desc: 'Lock the user after a number of failed login attempts, # of attempts',
    acceptable_value: function (system, option) {
      if (!system) return true;
      return system <= option;
    },
    account_manageable: false
  },
  failed_login_attempts_timeframe: {
    group: 'failed-login',
    type: 'string:PasswordExpire',
    render_value: render_PasswordExpire,
    levels: 'company',
    desc: 'Only failed login attempts within this timeframe count',
    values: [
    //      { v: '-',    l: 'never' },
    {
      v: '1d',
      l: '24 hours'
    }, {
      v: '7d',
      l: 'a week'
    }, {
      v: '30d',
      l: '30 days'
    }],
    acceptable_value: function (system, option) {
      if (!system) return true;
      if (option === '-') return false;
      const system_days = password_expire_value_to_days(system),
        option_days = password_expire_value_to_days(option);
      return option_days >= system_days;
    },
    account_manageable: true,
    default: "7d"
  },
  soft_lock_period: {
    group: 'failed-login',
    type: 'integer',
    levels: 'company',
    desc: 'After a number of failed login attempts, lock the account for that long',
    default: 15,
    acceptable_value: function (system, option) {
      if (!system) return true;
      return system <= option;
    },
    values: [{
      v: 5,
      l: '5 minutes'
    }, {
      v: 15,
      l: '15 minutes'
    }, {
      v: 30,
      l: '30 minutes'
    }, {
      v: 60,
      l: '60 minutes'
    }],
    account_manageable: true
  }
};
function policy_defaults() {
  // calculate (build) retval object, based on the policies constant
  // and the default attribute in it.
  // with current settings above, the retval must be:
  // {"soft_lock_period": 15}
  // (where default is not set, those policies do not get mentioned
  // in the output object)

  let retval = {};
  for (const policy in policies) {
    if (typeof policies[policy].default !== 'undefined') retval[policy] = policies[policy].default;
  }
  return retval;
}
module.exports = {
  policy_defaults,
  policies,
  policy_groups
};