"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UserObject = void 0;
exports.default = api_user;
require("core-js/modules/es.array.push.js");
var _object = require("../base/object");
var _constants = require("../../../api/constants");
/*
 */

class UserObject extends _object.BaseObject {
  constructor(api, id, data, req_opts) {
    super(api, "user", id, data, req_opts);
  }
  set_data(data) {
    this.apply_test_data(data);
    let dfd = $.Deferred();
    this.open_promise = dfd.promise();
    dfd.resolve(data);
    this.emit("change", data);
  }
  pref(key, value) {
    let u = this && this.data;
    if (!u) return;
    if (value === undefined) return u.pref && u.pref[key];
    if (u.ruser) return;
    if ((u.pref || {})[key] == value) return;
    let op_details = {
      ['pref/' + key]: value
    };
    return this.set_key(op_details, 50);
  }
  ac_track_event(name) {
    if (((this.data || {}).profile || {}).activecampaign_id && name) {
      this.op("acetrack", {
        n: name
      });
    }
  }
  pubGetRooms(data) {
    if (!this.data) return null;
    let rooms = ['/' + this.type + '/' + this.get_static_id()];
    if (this.data.company_id) {
      rooms.push('/company/' + this.data.company_id);
    }
    return rooms;
  }
  get companyLibraryAccess() {
    return this.data ? this.data.companyLibraryAccess : _constants.LIBRARY_ACCESS_TYPES.NONE;
  }
}
exports.UserObject = UserObject;
function api_user(id, data, req_opts) {
  let api = this;
  let u = new UserObject(api, id, data, req_opts);
  u.setMaxListeners(20);
  return u;
}